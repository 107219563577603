import React from "react";
import PartnersList from "./PartnersList";


const FooterNavBar = ({ title, address }) => {


    return (

        <div className="footer-navbar">
            <div className="row">
                <div className="col-lg-8">
                    <p>{title[0].title}</p>
                    <br/>
                    {address.map(item => (
                        <p>{item.content}</p>
                    ))}
                </div>
                <PartnersList title={title[1].title} />
            </div>
        </div>
    )
}

export default FooterNavBar