import { graphql, useStaticQuery } from "gatsby";



export const usePartnersData = () => {

    const data = useStaticQuery(graphql`
        query PartnersDataQuery {
            prismicPartners {
                data {
                  partner_group {
                    slug
                    name
                    description
                    icon {
                      url
                      alt
                      gatsbyImageData
                    }
                  }
                }
              }
        }
    `)

    const partnersData = data.prismicPartners.data.partner_group
    return partnersData
}