import React from "react";
import BottomBar from "../../components/Footer/BottomBar";
import FooterNavBar from "../../components/Footer/FooterNavBar";

const FooterSection = ({ title, address, links }) => {

    return (

        <>
            <FooterNavBar title={title} address={address} />
            <BottomBar links={links} />
        </>
    )
}

export default FooterSection