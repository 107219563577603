import React from "react";
import { LogoLight, Logo } from "../../utils/imgLoader";
import LogoPlacement from "../LogoPlacement/LogoPlacement";
import Hamburger from "./Habmurger";
import NavMenu from "./NavMenu";

const NavContainer = ({ src, hamburgerClsName, hamburgerHandler, spanClsn, navMenuClsName, headerItems, btn, clsNme}) => {

    let logo

    if (src === true) {
        logo = LogoLight
    }   else logo = Logo

    return (
        <nav className="header-nav">
            <LogoPlacement src={logo}/>
                <div className="d-flex">
                    <Hamburger 
                        hamburgerClsName={hamburgerClsName}
                        hamburgerHandler={hamburgerHandler}
                        spanClsn={spanClsn}
                    />
                    <NavMenu 
                        navMenuClsName={navMenuClsName}
                        headerItems={headerItems}
                        btn={btn}
                        clsNme={clsNme}
                    />
                </div>
        </nav>  
    )

}

export default NavContainer