import React from "react";

const HeaderItems = ({ headerItems, clsNme }) => {

    return (
        <>
            {headerItems.map(link => (
                <li className={"nav-item " }>
                    <a href={link.link} className={"nav-link " +clsNme }>
                        {link.name}
                    </a>
                </li>
            ))}
        </>
    )
}


export default HeaderItems