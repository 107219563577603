import React from "react";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";


function SEO({ title, description, lang, meta, keywords }) {

    const { site } = useStaticQuery(graphql`
        query {
            site {
            siteMetadata {
                title
                description
                keywords
            }
            }
        }
    `)

    const metaDescription = description || site.siteMetadata.description
    const metaTitle = title || site.siteMetadata.title
    const metaKeywords = keywords || site.siteMetadata.keywords

    return (
        <Helmet 
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: "keywords",
                    content: metaKeywords,
                },
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:title`,
                    content: metaTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: "robots",
                    content: meta ? meta : "follow, index",
                },
            ].concat({
                name: `keywords`,
                content: metaKeywords
            })}
        />
    )
}


SEO.defaultProps = {
    lang: `en`,
    meta: ``,
    keywords: ``,
    description: ``,
}
  
  SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.string,
    keywords: PropTypes.string,
    title: PropTypes.string,
  }
  
  export default SEO