import { graphql, useStaticQuery } from "gatsby";

export const useFooterData = () => {

    const data = useStaticQuery(graphql`
        query FooterDataQuery {
            prismicFooterdata {
                data {
                  address {
                    content
                  }
                  links {
                    title
                    url
                  }
                  titles {
                    title
                  }
                }
              }
        }
    `)
    const footerData = data.prismicFooterdata.data

    return footerData
}