import React from "react";


const FooterLinks = ({ links }) => {

    return (
        <>
        {links.map(link => (
            <a href={link.url}>
                {link.title}
            </a>
        ))}
        </>
    )
}



export default FooterLinks