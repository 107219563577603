import whiteAltradyLogo from "../assets/imgs/white-logo.png"
import logoLightSvg from "../assets/imgs/logo_light.svg"
import trendupicon from "../assets/imgs/trendUp.svg"
import trenddownicon from "../assets/imgs/trendDown.svg"
import questionIcon from "../assets/imgs/questionCircle.svg"
import emoji from "../assets/imgs/emoji.png"
import goIcon from "../assets/imgs/goto-icon.svg"
import cbsLogo from "../assets/imgs/cbsLogo.svg"
import Facebook from "../assets/imgs/facebook.png"
import Twitter from "../assets/imgs/twitter.png"
import Discord from "../assets/imgs/discord.png"
import Telegram from "../assets/imgs/telegram.png"
import ytButton from "../assets/imgs/playButton.webp"

export const PlayButton = ytButton
export const TelegramImg = Telegram
export const DiscordImg = Discord
export const TwitterImg = Twitter
export const FacebookImg = Facebook
export const Logo = cbsLogo
export const goToIcon = goIcon
export const starEmoji = emoji
export const toolTip = questionIcon
export const trendUp = trendupicon
export const trendDown = trenddownicon
export const AltradyLogo = whiteAltradyLogo
export const LogoLight = logoLightSvg