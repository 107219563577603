import React from "react";


const Hamburger = ({ hamburgerClsName, hamburgerHandler, spanClsn }) => {

    return (
        <div className={hamburgerClsName}
            onClick={hamburgerHandler}
            onKeyDown={hamburgerHandler}
            role="button"
            tabIndex="0"
        >
            <span className={spanClsn}></span>
            <span className={spanClsn}></span>
            <span className={spanClsn}></span>
        </div>
    )
}


export default Hamburger