import React from "react";
import { Image } from "../Image/Image";

const PartnersData = ({ partnersData }) => {

    return (
        partnersData.map((item, index) => (
            <a key={index} href={item.slug} className="footer-item_title">
                <div className="footer-item">
                    <div style={{ width: 50 }}>
                        <Image 
                            alt={item.name}
                            src={item.icon}
                            width={48}
                            height={48}
                            className="footer-item__image"
                            loading={"eager"}
                            fadeIn={false}
                        />
                    </div>
                    <div className="footer-item__content-wrapper">
                        {item.name}
                        <p className="label footer-item_content">{item.description}</p>
                    </div>
                </div>
            </a>
        ))
    )
}

export default PartnersData