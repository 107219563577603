import React  from "react";
import HeaderItems from "./HeaderItems";
import PrimaryButton from "../PrimaryButton/Primary Button";


const NavMenu = ({ navMenuClsName, headerItems, btn, clsNme }) => {


    return (
        <ul className={navMenuClsName}>
            <HeaderItems headerItems={headerItems} clsNme={clsNme}/>
            <li className="action-btns"> 
                <PrimaryButton btnText={btn} btnLink={"https://app.cryptobasescanner.com/account"}/> 
            </li>
        </ul>
    )
}

export default NavMenu