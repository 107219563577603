import React from "react";

const PrimaryButton = ({ btnText, btnLink, btnCls }) => {


    return (
        <a className={"btn btn-primary " + btnCls }
            href={btnLink}
        >
            {btnText}
         </a>
    )
}


export default PrimaryButton