import React from "react";
import { useFooterData } from "../StaticDataHooks/useFooterData";
import FooterSection from "../../sections/FooterSection.js/FooterSection";

const Footer = () => {

    const contentData = useFooterData()

    const title = contentData.titles
    const links = contentData.links
    const address = contentData.address


    return (

        <footer className="footer">
            <div className="layout-container">
                <FooterSection title={title} address={address} links={links}/>
            </div>
        </footer>
    )
}

export default Footer