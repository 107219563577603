import React from "react";
import {
     FacebookImg, 
     TwitterImg, 
     DiscordImg, 
     TelegramImg
    } from "../../utils/imgLoader";
import { Image } from "../Image/Image";



const SocialLinks = () => {

    const linksData = [
        {
            href: 'https://discord.com/invite/s5DuEhh',
            alt: 'Discord Link',
            src: DiscordImg,
        },
        {
            href: 'https://twitter.com/altradyapp',
            alt: 'Twitter Link',
            src: TwitterImg,
        },
        {
            href: 'https://t.me/altrady',
            alt: 'Telegram Link',
            src: TelegramImg,
        },
        {
            href: 'https://www.facebook.com/altradyapp/',
            alt: 'Facebook Link',
            src: FacebookImg,
        },

    ]

    return (
         <div className="soial-links">
             {linksData.map(item => (
                <a href={item.href}>
                    <Image 
                        alt={item.alt} 
                        src={item.src} 
                        loading={"eager"}
                        fadeIn={false}
                    />
                </a>
             ))}
         </div>

    )
}


export default SocialLinks