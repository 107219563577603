import React from "react";
import { usePartnersData } from "../StaticDataHooks/usePartnersData";
import PartnersData from "./PartnersData";

const PartnersList = ({ title }) => {

    const partnersData = usePartnersData()

    return (

        <div className="col-lg-4">
            <h4>{title}</h4>
            <PartnersData partnersData={partnersData}/>
        </div>
    )
}

export default PartnersList