import React from "react";
import { Logo } from "../../utils/imgLoader";
import { Link } from "gatsby";
import { Image } from "../Image/Image";

const LogoPlacement = ({ src }) => {

    if(!src){
        src = Logo
    }

    return (
        <Link to="/"> 
            <Image 
                alt="Crypto Base Scanner Logo"
                src={src}
                loading={"eager"}
                fadeIn={false}
            />
        </Link>
    )
}

export default LogoPlacement