import React from "react";
import LogoPlacement from "../LogoPlacement/LogoPlacement";
import SocialLinks from "./SocialLinks";
import FooterLinks from "./FooterLinks";


const BottomBar = ({ links }) => {

    return(
        <div className="bottom-bar">
            <LogoPlacement />
            <div className="links">
                <div className="terms">
                    <FooterLinks links={links} />
                </div>
                <SocialLinks />
            </div>
        </div>
    )
}

export default BottomBar